<template>
  <div v-if="banner.slug" class="dropdowns-menu__col dropdowns-menu__col-banner">
    <uc-link :to="banner.link">
      <uc-image :img="banner.img" :alt="banner.alt" folder="slider-image" height="650" />
    </uc-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    category: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      menuBanners: (state) => state.catalog.menuBanners
    }),
    banner() {
      return this.menuBanners.desktop.find?.((item) => item.slug === this.category) || {};
    }
  }
};
</script>
