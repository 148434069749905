<template>
  <uc-modal v-model="visible" class="birthday-bonus" @hide-trigger="handleHideTrigger">
    <div class="info">
      {{ userName }}! <br />{{ $t('Получайте') }} <br v-if="isScreenMobile" />
      <span class="info__double">{{ $t('двойной кешбэк') }}</span>
      <br />{{ $t('в честь') }} <span class="info__birthday">{{ $t('дня рождения') }}</span>
      <br v-if="!isScreenMobile" />{{ $t('вашего малыша') }}<br />
      {{ $t('c') }} {{ startDate }} {{ $t('по') }} {{ endDate }}
    </div>
    <div class="uc-button uc-button-blue" @click="hideModal(), handleHideTrigger(CLOSE_BUTTON_TRIGGER)">
      {{ $t('Продолжить покупки') }}
    </div>
  </uc-modal>
</template>

<script>
import UcBirthdayBonusModal from 'u-components/components/modals/UcBirthdayBonusModal.vue';

import { CLOSE_BUTTON_TRIGGER } from 'u-components/components/lib/UcModal.vue';
import { ScreenWidthMixin } from 'u-mixins';

export default {
  extends: UcBirthdayBonusModal,
  mixins: [ScreenWidthMixin],
  computed: {
    CLOSE_BUTTON_TRIGGER: () => CLOSE_BUTTON_TRIGGER
  }
};
</script>

<style lang="scss">
@import '~/assets/scss/modals/modal-birthday.scss';
</style>
