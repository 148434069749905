<template>
  <div v-if="childList.length" class="dropdowns-menu__col">
    <div class="dropdowns-menu__head">{{ $t(`${title}`) }}</div>
    <ul class="menu-list menu-list_drop" :class="{ 'menu-list-width': childList.length > 13 }">
      <li v-for="(child, key) in childList" :key="`menu-list-drop-${key}`" class="menu-list__item">
        <uc-link
          :to="localePath({ name: 'product-catalog-page', params: buildParams(folderCategory, child, genderFilter) })"
          class="menu-list__text menu-list__text_drop"
          :aria-label="$t(`${child.title}`)"
          :class="linkClass"
        >
          {{ $t(child.title) }}
        </uc-link>
      </li>
    </ul>
  </div>
</template>

<script>
import NavigationRouteMixin from 'u-mixins/lib/NavigationRoute.mixin.js';

export default {
  mixins: [NavigationRouteMixin],
  props: {
    title: {
      type: String,
      default: ''
    },
    childList: {
      type: Array,
      default: () => []
    },
    childGender: {
      type: Number,
      default: null
    },
    folderCategory: {
      type: String,
      default: ''
    },
    linkClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    genderFilter() {
      let filters = [];
      if (this.childGender) {
        filters = [{ topic: 'gender', filters: [this.childGender] }];
      }

      return filters;
    }
  }
};
</script>
