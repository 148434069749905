<template>
  <li
    :class="{ hovered: isHovered }"
    class="navigation-list__item"
    @mouseenter="mouseEnterHandler()"
    @mouseleave="mouseLeaveHandler()"
    @click="hideDropdown()"
  >
    <uc-link
      :to="localePath({ name: 'product-catalog-page', params: buildParams(category.slug) })"
      :aria-label="category.title"
      class="navigation-list__link"
      @click.native="toSubcategory(category.slug)"
      ><div class="navigation-ico">
        <img
          :src="`https://static.chicco.com.ua/chicco-assets/img/menu/80/${category.slug}.png`"
          :alt="category.slug"
        />
      </div>
      <span>{{ category.title }}</span
      ><img class="navigation-arrow" src="https://static.chicco.com.ua/chicco-assets/svg/menu-arrow.svg" alt="arrow"
    /></uc-link>
    <category-column-list :category="category" />
  </li>
</template>

<script>
import CategoryColumnList from '~/src/components/general/navigation/CategoryColumnList.vue';
import MouseHandlerMixin from '~/src/components/general/navigation/mixin/MouseHandler.mixin.js';
import NavigationRouteMixin from 'u-mixins/lib/NavigationRoute.mixin.js';
import * as CatalogMutation from 'u-catalog/lib/store/mutation-types.js';

export default {
  components: {
    CategoryColumnList
  },
  mixins: [MouseHandlerMixin, NavigationRouteMixin],
  props: {
    category: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    toSubcategory(folderCategory) {
      this.$store.commit(`catalog/${CatalogMutation.SET_LAST_ACTIVE_TOPIC}`, null);
      this.$eventBus.$emit('catalog:active-filters:clear', folderCategory);
    },
    hideDropdown() {
      this.$eventBus.$emit('nav:overlay:remove');
      this.hideMenu();
    }
  }
};
</script>
