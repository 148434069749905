<template>
  <header>
    <div class="header" :class="{ 'fixed-header': stickyHeader }">
      <div class="container">
        <div class="header-top">
          <app-mobile-navigation />
          <logo class="header-top__logo" />
          <nav class="header-top__nav">
            <div class="header-top__nav-left-side">
              <div class="call-center">
                <a class="call-center__number" href="tel:0443230912">(044) 323-09-12</a>
                <div v-if="false" class="call-center__text">● {{ $t('Call-центр работает') }}</div>
              </div>
            </div>
            <div class="header-top__nav-right-side">
              <uc-link
                :to="localePath({ name: 'promotion-page' })"
                class="header-top__nav-right-side-link promotions"
                >{{ $t('Акции') }}</uc-link
              >
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'subscription' } })"
                class="header-top__nav-right-side-link babycard"
                >{{ $t('МА Клуб') }}
              </uc-link>
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'bonus-rules' } })"
                class="header-top__nav-right-side-link babycard"
                >{{ $t('МА Кешбэк') }}
              </uc-link>

              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'service-warranty' } })"
                class="header-top__nav-right-side-link guarantee"
              >
                {{ $t('Гарантия и сервис') }}
              </uc-link>
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'delivery' } })"
                class="header-top__nav-right-side-link payment-and-delivery"
              >
                {{ $t('Оплата и доставка') }}
              </uc-link>
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'shops' } })"
                class="header-top__nav-right-side-link shops"
                >{{ $t('Магазины') }}</uc-link
              >
              <language-switcher class="header-top__nav-right-side-link" />
              <a href="tel:0443230912" class="header-top__nav-right-side-link call-center">
                <img src="https://static.chicco.com.ua/chicco-assets/svg/header/phone.svg" alt="call-center" />
                <div class="text">(044) 323-09-12</div>
                <img
                  class="arrow"
                  src="https://static.chicco.com.ua/chicco-assets/svg/arrow-down-blue.svg"
                  alt="Arrow"
                />
                <div class="call-center-popup">
                  <div class="call-center-popup-wrapper">
                    <p class="call-center-popup-text">{{ $t('График работы Call-центра') }}:</p>
                    <p class="call-center-popup-text">{{ $t('пн-вс: с') }} <b>09:00</b> {{ $t('до') }} <b>20:00</b></p>
                  </div>
                </div>
              </a>
              <wish-list-widget />
              <authorisation-widget />
              <basket-widget />
            </div>
          </nav>
        </div>
        <div class="header-bottom">
          <app-navigation />
          <UcQuickSearch />
        </div>
      </div>
    </div>
    <div v-if="stickyHeader" class="fixed-header-dummy" />
  </header>
</template>

<script>
import UcQuickSearch from 'u-components/components/lib/search/UcQuickSearch.vue';
import { ScreenWidthMixin } from 'u-mixins';

import Logo from '~/src/components/general/Logo.vue';
import AuthorisationWidget from '~/src/components/page-blocks/AuthorisationWidget.vue';
import AppNavigation from '~/src/components/general/navigation/AppNavigation.vue';
import AppMobileNavigation from '~/src/components/general/navigation/AppMobileNavigation.vue';
import BasketWidget from '~/src/components/page-blocks/BasketWidget.vue';
import LanguageSwitcher from '~/src/components/general/LanguageSwitcher.vue';
import WishListWidget from '~/src/components/general/navigation/WishListWidget.vue';

export default {
  components: {
    UcQuickSearch,
    Logo,
    AppNavigation,
    AppMobileNavigation,
    AuthorisationWidget,
    BasketWidget,
    LanguageSwitcher,
    WishListWidget
  },
  mixins: [ScreenWidthMixin],
  data() {
    return {
      navVisibility: false,
      stickyHeader: false,
      displayTop: true
    };
  },
  computed: {
    headerHeight() {
      if (this.isScreenSmallDesktop) {
        return 100;
      } else if (this.isScreenTablet) {
        return 95;
      } else if (this.isScreenMobile) {
        return 90;
      } else {
        return 105;
      }
    }
  },
  watch: {
    '$route.path': {
      handler(val) {
        this.navVisibility =
          (val === '/' && this.stickyHeader === false) || (val === '/ua' && this.stickyHeader === false);
      }
    }
  },
  mounted() {
    this.navVisibility =
      (this.$route.path === '/' && this.stickyHeader === false) ||
      (this.$route.path === '/ua' && this.stickyHeader === false);
    global.addEventListener('scroll', this.handleScroll);
    this.$eventBus.$on('app:unfixed:header', this.unfixTop);
    this.$eventBus.$on('app:fixed:header', this.fixTop);
  },
  beforeDestroy() {
    global.removeEventListener('scroll', this.handleScroll);
    this.$eventBus.$off('app:unfixed:header', this.unfixTop);
    this.$eventBus.$off('app:fixed:header', this.fixTop);
  },
  methods: {
    handleScroll() {
      this.stickyHeader = global.scrollY >= this.headerHeight && this.displayTop;
      this.navVisibility =
        (this.$route.path === '/' && !this.stickyHeader) || (this.$route.path === '/ua' && !this.stickyHeader);
    },
    unfixTop() {
      this.displayTop = false;
    },
    fixTop() {
      this.displayTop = true;
    }
  }
};
</script>

<style lang="scss">
@import '~/assets/scss/header/header.scss';
</style>
