<template>
  <section class="mobile-menu--visibility">
    <div v-if="menuCategories.length" class="burger" @click.stop="toggleMobileMenu">
      <img
        class="burger__img"
        src="https://static.chicco.com.ua/chicco-assets/svg/header/menu-dropdown-gray.svg"
        alt="burger"
      />
    </div>
    <div v-click-outside="closeMobileMenu" :class="{ 'is-open': mobileMenu }" class="mobile-menu">
      <div class="close-overlay" @click="closeMobileMenu">
        <img src="https://static.chicco.com.ua/chicco-assets/svg/close-red.svg" alt="close" />
      </div>
      <div :class="{ 'is-show': mobileMenu }" class="mobile-menu__wrap">
        <div class="mobile-menu-head">
          <div class="mobile-menu-head__close" @click="closeMobileMenu">
            <img src="https://static.chicco.com.ua/chicco-assets/svg/mobile-nav-close.svg" alt="close" />
          </div>
          <logo class="mobile-menu-head__logo" />
          <language-switcher class="mobile-menu-head__item" silent />
        </div>
        <div class="mobile-menu-body">
          <div class="mobile-menu-wrapper" :class="{ 'display-none': isSubCategories }">
            <ul class="menu-wrapper__list">
              <li
                v-for="(category, index) in menuCategories"
                :key="`mobile-catigories-${index}`"
                class="menu-wrapper__list-item"
                @click="setCategory(category)"
              >
                <div class="menu-wrapper__list-item__link">
                  <div class="menu-wrapper__list-item__link-img">
                    <img
                      :src="`https://static.chicco.com.ua/chicco-assets/img/menu/80/${category.slug}.png`"
                      :alt="category.slug"
                    />
                  </div>
                  <span class="menu-wrapper__list-item__link-title">{{ $t(category.title) }}</span>
                  <img
                    class="menu-wrapper__list-item__link-arrow"
                    src="https://static.chicco.com.ua/assets/svg/menu/menu-arrow.svg"
                    alt="arrow"
                  />
                </div>
              </li>
              <mobile-outlet />
            </ul>
          </div>
          <div v-show="isSubCategories && !isFilteredSubCategories" class="mobile-menu-wrapper">
            <div class="menu-wrapper__list-item__link" @click="toCategory()">
              <img
                class="menu-wrapper__list-item__link-arrow__back"
                src="https://static.chicco.com.ua/assets/svg/menu/menu-arrow.svg"
                alt="arrow"
              />
              <span class="menu-wrapper__list-item__link-title">{{ categoryTitle }}</span>
            </div>
            <div class="wrapper-list__item-show-all" @click="closeMobileMenu()">
              <uc-link
                v-if="folderCategory.slug"
                :to="localePath({ name: 'product-catalog-page', params: buildParams(folderCategory.slug) })"
                class="menu-wrapper__list-item__link"
              >
                {{ $t('Показать все') }}
              </uc-link>
            </div>
            <ul class="menu-wrapper__list">
              <div v-for="(category, index) in subCategories" :key="`sub-categories-${index}`">
                <div v-if="category.childList && category.childList.length">
                  <li
                    v-if="folderCategory.slug === 'odezhda' || folderCategory.slug === 'obuv'"
                    class="menu-wrapper__list-item"
                    @click="setSubCategory(category)"
                  >
                    <div class="menu-wrapper__list-item__link">
                      <span>{{ $t(category.title) }}</span>
                      <img
                        class="menu-wrapper__list-item__link-arrow"
                        src="https://static.chicco.com.ua/assets/svg/menu/menu-arrow.svg"
                        alt="arrow"
                      />
                    </div>
                  </li>
                </div>
                <li
                  v-if="folderCategory.slug !== 'odezhda' && folderCategory.slug !== 'obuv'"
                  class="menu-wrapper__list-item"
                  @click="closeMobileMenu()"
                >
                  <uc-link
                    :to="
                      localePath({ name: 'product-catalog-page', params: buildParams(folderCategory.slug, category) })
                    "
                    class="menu-wrapper__list-item__link"
                    >{{ $t(category.title) }}</uc-link
                  >
                </li>
              </div>
            </ul>
          </div>
          <div v-show="isSubCategories && isFilteredSubCategories" class="mobile-menu-wrapper">
            <div class="menu-wrapper__list-item" @click="toSubCategory()">
              <div class="menu-wrapper__list-item__link">
                <img
                  class="menu-wrapper__list-item__link-arrow__back"
                  src="https://static.chicco.com.ua/assets/svg/menu/menu-arrow.svg"
                  alt="arrow"
                />
                <span class="menu-wrapper__list-item__link-folder-item">{{ $t(subCategoryTitle) }}</span>
                <span class="menu-wrapper__list-item__link-folder-title">{{ $t(categoryTitle) }}</span>
              </div>
            </div>
            <ul class="menu-wrapper__list">
              <li
                v-for="(category, index) in filteredSubCategories"
                :key="`filtered-sub-categories-${index}`"
                class="menu-wrapper__list-item"
                @click="closeMobileMenu()"
              >
                <uc-link
                  :to="
                    localePath({
                      name: 'product-catalog-page',
                      params: buildParams(folderCategory.slug, category, gender)
                    })
                  "
                  class="menu-wrapper__list-item__link"
                  >{{ $t(category.title) }}</uc-link
                >
              </li>
            </ul>
          </div>
          <div class="mobile-menu-separator" />
          <a href="tel:0443230906" class="mobile-menu-contact"
            ><img src="https://static.chicco.com.ua/chicco-assets/svg/contact-phone.svg" alt="close" />(044)
            323-09-06</a
          >
          <ul class="mobile-menu-sublist">
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'about-shop' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('О нас') }}</uc-link
              >
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'shops' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('Наши магазины') }}</uc-link
              >
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'contact-us' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('Обратная связь') }}</uc-link
              >
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'delivery' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('Доставка и оплата') }}</uc-link
              >
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'return-policy' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('Возврат и обмен') }}</uc-link
              >
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'service-warranty' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('Сервис и гарантия') }}</uc-link
              >
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'bonus-rules' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('Программа МА Кешбэк') }}</uc-link
              >
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'subscription' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('МА Клуб') }}</uc-link
              >
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link :to="localePath({ name: 'promotion-page' })" class="mobile-menu-sublist__link">{{
                $t('Акции и распродажи')
              }}</uc-link>
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link :to="localePath({ name: 'pakunok-malyuka-page' })" class="mobile-menu-sublist__link">{{
                $t('Пакунок малюка')
              }}</uc-link>
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'gift-sertificate' } })"
                class="mobile-menu-sublist__link"
              >
                {{ $t('Подарочные сертификаты') }}
              </uc-link>
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link :to="localePath({ name: 'blog-page' })" class="mobile-menu-sublist__link">{{
                $t('Блог')
              }}</uc-link>
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'purchase-terms' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('Условия покупки') }}</uc-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div :class="{ block: mobileMenu }" class="mobile-menu-overlay" @click.self="closeMobileMenu" />
  </section>
</template>

<script>
import { SortableMixin, TranslatorMixin, RouterMixin } from 'u-mixins';
import vClickOutside from 'v-click-outside';
import { mapGetters } from 'vuex';

import Logo from '~/src/components/general/Logo.vue';
import MobileOutlet from '~/src/components/general/navigation/MobileOutlet.vue';
import LanguageSwitcher from '~/src/components/general/LanguageSwitcher.vue';

import NavigationRouteMixin from 'u-mixins/lib/NavigationRoute.mixin.js';

const TABLET_SCREEN = 768;

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    Logo,
    MobileOutlet,
    LanguageSwitcher
  },
  mixins: [SortableMixin, TranslatorMixin, RouterMixin, NavigationRouteMixin],
  data() {
    return {
      mobileMenu: false,
      categoryTitle: '',
      subCategoryTitle: '',
      folderCategory: '',
      subCategories: null,
      isSubCategories: false,
      filteredSubCategories: null,
      isFilteredSubCategories: false,
      gender: null,
      screenWidth: '',
      CUSTOM_FILTERS_AGES: [
        { title: '0 - 12 мес', url: { filters: [{ topic: 'age', filters: ['0-6-mes', '6-12-mes'] }] } },
        {
          title: '12 - 36 мес',
          url: { filters: [{ topic: 'age', filters: ['12-24-mes', '2-3-goda'] }] }
        },
        { title: '3 - 8 лет', url: { filters: [{ topic: 'age', filters: ['3-8-let'] }] } },
        { title: '9 - 12 лет', url: { filters: [{ topic: 'age', filters: ['9-12-let'] }] } },
        { title: '13 - 16 лет', url: { filters: [{ topic: 'age', filters: ['13-16-let'] }] } }
      ],
      TOPIC_SLUG_AGE: 'age'
    };
  },
  computed: {
    ...mapGetters({
      menuCategories: 'catalog/visibleCategories'
    }),
    TABLET_SCREEN: () => TABLET_SCREEN
  },
  watch: {
    mobileMenu(isOpen) {
      this.$eventBus.$emit('general:body:hold-scroll', isOpen);
      if (isOpen) {
        this.toMenuTop();
      }
    }
  },
  mounted() {
    this.screenWidth = global.innerWidth;
    global.addEventListener('resize', this.handleResize);
    this.$eventBus.$on('menu:mobile:display', this.displayMobileMenu);
    this.$eventBus.$on('menu:mobile:hide', this.closeMobileMenu);
  },
  beforeDestroy() {
    global.removeEventListener('resize', this.handleResize);
    this.$eventBus.$off('menu:mobile:display', this.displayMobileMenu);
    this.$eventBus.$off('menu:mobile:hide', this.closeMobileMenu);
    this.$eventBus.$emit('general:body:hold-scroll', false);
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenu = !this.mobileMenu;
    },
    closeMobileMenu() {
      this.mobileMenu = false;
    },
    displayMobileMenu() {
      this.mobileMenu = true;
    },
    displayDialog(tab) {
      this.$eventBus.$emit('modals:register:open', tab);
    },
    handleResize() {
      this.screenWidth = global.innerWidth;
    },
    showLoginModal() {
      this.mobileMenu = false;
      this.$eventBus.$emit('modals:register:open', 'login');
    },
    toProfile() {
      this.mobileMenu = false;
      this.moveToByName('profile-page');
    },
    setCategory(category) {
      this.folderCategory = category;
      this.categoryTitle = this.folderCategory.title;
      this.isSubCategories = true;
      this.gender = null;

      if (category.slug === 'odezhda' || category.slug === 'obuv') {
        const customChildList = [
          {
            title: 'Для мальчиков',
            childList: category.childList,
            gender: '1'
          },
          {
            title: 'Для девочек',
            childList: category.childList,
            gender: '2'
          },
          {
            title: 'Возраст',
            slug: 'age',
            childList: this.$utils.cloneObj(this.CUSTOM_FILTERS_AGES)
          }
        ];

        this.subCategories = this.$utils.cloneObj(customChildList);
      }

      if (category.slug !== 'odezhda' && category.slug !== 'obuv') {
        this.subCategories = category.childList;

        const categoryList = this.subCategories.map((value) => ({
          title: value.title,
          url: { category: [value.slug] }
        }));

        this.subCategories = categoryList;
      }

      this.toMenuTop();
    },
    toCategory() {
      this.isSubCategories = false;
      this.toMenuTop();
    },
    setSubCategory(category) {
      this.categoryTitle = this.folderCategory.title;
      this.subCategoryTitle = category.title;
      this.isFilteredSubCategories = true;
      this.filteredSubCategories = null;
      this.gender = null;

      if (category.gender) {
        this.gender = [{ topic: 'gender', filters: [category.gender] }];
      }

      if (category.slug !== 'age') {
        this.filteredSubCategories = category.childList.map((value) => ({
          title: value.title,
          url: { category: [value.slug] }
        }));
      } else {
        this.filteredSubCategories = category.childList.length && category.childList;
      }

      // Disable platya-i-yubki for boys subcategory
      if (this.folderCategory.slug === 'odezhda' && category.gender === '1') {
        this.filteredSubCategories = this.filteredSubCategories.filter(
          (item) => !item.url.category.includes('platya-i-yubki')
        );
      }

      this.toMenuTop();
    },
    toSubCategory() {
      this.isFilteredSubCategories = false;
      this.toMenuTop();
    },
    getFiltersList(topicSlug) {
      try {
        const topic = this.folderCategory.topicList.find((value) => value.slug === topicSlug) || null;

        return ((topic && topic.filterList) || []).map((value) => ({
          title: this.getTranslationBySlug(value.slug),
          url: { filters: [{ topic: topic.slug, filters: [value.slug] }] }
        }));
      } catch (e) {
        if (this.$sentry) {
          this.$sentry.captureException(e);
        }
      }
    },
    toMenuTop() {
      const el = document.querySelector('.mobile-menu-body');
      if (el) {
        el.scrollTo(0, 0);
      }
    }
  }
};
</script>
<style lang="scss">
@import '~/assets/scss/navigation/navigation-mobile.scss';
</style>
