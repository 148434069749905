<template>
  <nav class="navigation" @mouseover="addOverlay()" @mouseleave="removeOverlay()">
    <div class="navigation__button">
      <img
        class="navigation-button__img"
        src="https://static.chicco.com.ua/chicco-assets/svg/header/menu-dropdown-white.svg"
        alt="Menu"
      />{{ $t('Категории') }}
    </div>
    <noscript inline-template>
      <ul v-cloak class="navigation__list">
        <li class="navigation-list__item" v-for="(item, index) in menuCategories" :key="`category-config-${index}`">
          <uc-link
            :to="localePath({ name: 'product-catalog-page', params: { folderCategory: item.slug } })"
            :aria-label="item.title"
            class="navigation-list__link"
            ><div class="navigation-ico">
              <img :src="`https://static.chicco.com.ua/chicco-assets/img/menu/80/${item.slug}.png`" :alt="item.slug" />
            </div>
            <span>{{ item.title }}</span
            ><img
              class="navigation-arrow"
              src="https://static.chicco.com.ua/chicco-assets/svg/menu-arrow.svg"
              alt="arrow"
          /></uc-link>
        </li>

        <li class="navigation-list__item navigation-list__sale">
          <uc-link
            :to="localePath({ name: 'outlet-page' })"
            class="navigation-list__link"
            title="Outlet"
            aria-label="Outlet"
            @click.native="$eventBus.$emit('outlet:refresh-search')"
            ><div class="navigation-ico">
              <img :src="`https://static.chicco.com.ua/chicco-assets/img/menu/80/80x80_outlet_mob.png`" alt="outlet" />
            </div>
            <span>Outlet</span></uc-link
          >
        </li>
      </ul>
    </noscript>
    <ul
      v-cloak
      :class="{ invisible: !isVisible && !visibility }"
      class="navigation__list"
      @mouseover="addIsVisibleOverlay()"
      @mouseleave="mouseLeaveHandler()"
    >
      <category-item v-for="(item, index) in menuCategories" :key="`category-config-${index}`" :category="item" />

      <li class="navigation-list__item navigation-list__sale" @click="removeOverlay">
        <uc-link
          :to="localePath({ name: 'outlet-page' })"
          class="navigation-list__link"
          title="Outlet"
          aria-label="Outlet"
          @click.native="$eventBus.$emit('outlet:refresh-search')"
          ><div class="navigation-ico">
            <img :src="`https://static.chicco.com.ua/chicco-assets/img/menu/80/80x80_outlet_mob.png`" alt="outlet" />
          </div>
          <span>Outlet</span></uc-link
        >
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import CategoryItem from '~/src/components/general/navigation/CategoryItem.vue';

export default {
  components: {
    CategoryItem
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visibility: false,
      overlay: null,
      timeout: null
    };
  },
  computed: {
    ...mapGetters({
      menuCategories: 'catalog/visibleCategories'
    })
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.getMenuBanners();
      }
    }
  },
  mounted() {
    this.$eventBus.$on('nav:overlay:remove', this.removeOverlay);
    this.getMenuBanners();
  },
  beforeDestroy() {
    this.$eventBus.$off('nav:overlay:remove', this.removeOverlay);
    this.removeOverlay();
  },
  methods: {
    ...mapActions({
      getMenuBanners: 'catalog/getMenuBanners'
    }),
    addOverlay() {
      if (!this.isVisible) {
        this.visibility = true;
        this.$eventBus.$emit('app-backdrop:display');
      }
    },
    removeOverlay() {
      setTimeout(() => {
        clearTimeout(this.timeout);
      });
      this.$eventBus.$emit('app-backdrop:hide');
      this.visibility = false;
    },
    mouseLeaveHandler() {
      this.$eventBus.$emit('nav:dropdown:hide');
    },
    addIsVisibleOverlay() {
      clearTimeout(this.timeout);
      if (this.isVisible) {
        this.timeout = setTimeout(() => {
          this.$eventBus.$emit('app-backdrop:display');
        }, 350);
        this.visibility = true;
      }
    }
  }
};
</script>
<style lang="scss">
@import '~/assets/scss/navigation/navigation.scss';
</style>
