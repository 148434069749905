<template>
  <div class="language-switcher">
    <n-link
      v-for="(locale, index) in availableLocales"
      :key="`uc-language-switcher-${index}-${locale}`"
      class="language-switcher__item"
      :class="{ active: currentLang === locale.code }"
      :to="locale.path"
      @click.native="() => changeUserLanguage(locale.code)"
    >
      {{ convertLocalCode(locale.code) }}
    </n-link>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import { CHANGE_LANGUAGE } from 'u-store/lib/user/mutation-types.js';

export default {
  props: {
    // This component is used twice on page, one should ignore watchers
    silent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      availableLocales: [],
      loading: false
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.data
    }),
    ...mapGetters({
      isAuth: 'user/isAuth'
    }),
    currentLang() {
      return this.$i18n.locale;
    }
  },
  watch: {
    '$i18n.locale': {
      handler() {
        if (this.silent) {
          return;
        }
        this.fetchCategoryConfig();
        this.fetchShopsConfig(true);
        this.checkBasket();
      },
      immediate: false
    },
    $route() {
      if (this.silent) {
        return;
      }
      this.buildAvailableLocales();
    },
    'userData.language'() {
      if (this.silent) {
        return;
      }
      if (this.userData.language) {
        this.setLanguage(this.userData.language);
      }
    }
  },
  created() {
    this.buildAvailableLocales();
  },
  mounted() {
    this.$eventBus.$on('catalogUrl:changed', ({ path }) => this.buildAvailableLocales(path));
  },
  methods: {
    ...mapMutations({
      [CHANGE_LANGUAGE]: `user/${CHANGE_LANGUAGE}`
    }),
    ...mapActions({
      fetchCategoryConfig: 'catalog/fetchCategoryConfig',
      fetchShopsConfig: 'catalog/fetchShopsConfig',
      getUserData: 'user/getUserData'
    }),
    buildAvailableLocales(pathWithoutLocale) {
      this.availableLocales = (this.$i18n.locales || []).map((locale) => ({
        ...locale,
        path:
          (pathWithoutLocale && this.toLocalePath(pathWithoutLocale, locale.code)) || this.switchLocalePath(locale.code)
      }));
    },
    convertLocalCode(code) {
      switch (code) {
        case 'ua':
          return 'Укр';
        case 'ru':
          return 'Рус';
        default:
          return '';
      }
    },
    async changeUserLanguage(newLang) {
      try {
        if (!this.isAuth || this.userData.language === newLang || this.loading) {
          return;
        }
        this.loading = true;
        await this.$api.UserService.changeLanguage(newLang);
        this.CHANGE_LANGUAGE(newLang);
        this.getUserData();
      } catch (error) {
        console.error('Change User Language Error: ', error);
      } finally {
        this.loading = false;
      }
    },
    async setLanguage(newLang) {
      try {
        if (this.currentLang !== newLang) {
          await this.$i18n.setLocale(newLang);
        }
      } catch (error) {
        this.$sentry.captureException(error);
        console.error(error);
      }
    },
    checkBasket() {
      if (this.$store.state.basket.basket) {
        this.$basket.checkBasket();
      }
    }
  }
};
</script>
